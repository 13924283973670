.divider {
    width: 100%;
    height: 2px;
    background-color: $c-copper;
    margin: 1rem 0;
    
    @include media-breakpoint-up (lg) {
        margin: 3rem 0;        
    }

    + h1, h2, h3, h4 ,h5 ,h6, p, ul {
        margin-top: 0;
    }
}