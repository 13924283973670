.logo {
    display: inline-block;
    height: auto;
    width: 14rem;

    @include media-breakpoint-up (lg) {
        width: 15rem;
    }
    
    @include media-breakpoint-up (xl) {
        width: 15rem;        
    }

    svg {
        rect {
            fill: $c-black;
        }
    }
}
