.u-- {

  &last-child-mb-0 {
    >:last-child {
      margin-bottom: 0;
    }
  }
}


// Breakpoint detection for javascript using bootstrap 4
#device-size-detector {
  position: absolute;
  z-index: -1;
  visibility: hidden;

  >div {
    width: 1px;
    height: 1px;

    &#xs {
      background-color: red;
    }

    &#sm {
      background-color: orange;
    }

    &#md {
      background-color: yellow;
    }

    &#lg {
      background-color: greenyellow;
    }

    &#xl {
      background-color: cornflowerblue;
    }

    &#xxl {
      background-color: blue;
    }

    &#xxxl {
      background-color: blueviolet;
    }
  }
}
