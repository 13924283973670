.article {
    max-width: 64rem;
    margin: 6vh auto;
    width: calc(100% - 6rem);
    
    @include media-breakpoint-up (lg) {
        width: 50%;        
    }

    &__footer {
        margin-top: 6vh;
        padding-top: 6vh;
        border-top: 2px solid $c-black;
        
        h1, h2, h3, h4 {
            margin-top: 0;
        }
    }

    &__all {
        font-weight: $fw-black;
        border-bottom: 2px solid $c-black;
        text-align: center;
        display: inline-block;
        margin: 2rem auto;

        &:hover {
            text-decoration: none;
            border-color: $c-copper;
        }
    }
}