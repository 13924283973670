// #map {
//     width: 100%;
//     height: 50vh;

//     @include media-breakpoint-up (xl) {
//         height: 50vh;
//     }

//     @include media-breakpoint-up (xxxl) {
//         height: 33vh;
//     }
// }

.map {
    width: 100%;
    height: 66vh;

    @include media-breakpoint-up (xl) {
        height: 50vh;
    }
}