.teaser {
    display: flex;
    flex-direction: column;
    width: calc(100% - 6rem);
    margin: auto;
    padding: 3rem 0;
    
    @include media-breakpoint-up (md) {
        padding: 6rem 0;
    }
    
    @include media-breakpoint-up (lg) {
        flex-direction: row;
        align-items: center;
        width: calc(100% - 6rem);
        margin-left: 0;
        margin-right: auto;
    }
    
    @include media-breakpoint-up (xl) {
        width: 75%;
        padding: 9rem 0;
    }

    &--right {

        @include media-breakpoint-up (lg) {
            flex-direction: row-reverse;
            margin-left: auto;
            margin-right: 0;
        }

        .teaser__lbl {
            padding-right: 6rem;
            padding-left: 0;
        }
    }

    &__lbl {
        flex-basis: 50%;
        padding-top: 3rem;

        @include media-breakpoint-up (lg) {
            padding-top: 0;
            padding-left: 6rem;
        }

        >*:last-child {
            padding-bottom: 0;
        }
    }

    &__title {
        display: block;
        margin-bottom: 0;

        >*:last-child {
            margin-bottom: 0;
        }
    }
    
    &__copy {
        
        >*:last-child {
            margin-bottom: 0;
        }
    }

    &__line {
        width: 100%;
        height: 2px;
        background-color: $c-copper;
        margin: 1.5rem 0;

        @include media-breakpoint-up (lg) {
            margin: 3rem 0;
        }
    }

    &__img {
        flex-basis: 50%;
    }
}