html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


//== Headlines
//

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: $fw-black;
    text-transform: uppercase;
}

h1,
.h1 {
    font-size: $font-size-h3;

    @include media-breakpoint-up (md) {
        font-size: $font-size-h2;
    }

    @include media-breakpoint-up (lg) {
        font-size: $font-size-h1;
        margin-top: $font-size-h1 * 2;
        margin-bottom: $font-size-h1;
    }
}

h2,
.h2 {
    font-size: $font-size-h3;

    @include media-breakpoint-up (lg) {
        font-size: $font-size-h2;
        margin-top: $font-size-h2 * 2;
        margin-bottom: $font-size-h2;
    }
}

h3,
.h3 {
    font-size: $font-size-h4;

    @include media-breakpoint-up (lg) {
        font-size: $font-size-h3;
        margin-top: $font-size-h3 * 2;
        margin-bottom: $font-size-h3;
    }
}

h4,
.h4 {
    font-size: $font-size-h5;

    @include media-breakpoint-up (lg) {
        font-size: $font-size-h4;
        margin-top: $font-size-h4 * 2;
        margin-bottom: $font-size-h4;
    }
}

h5,
.h5 {
    font-size: $font-size-h6;

    @include media-breakpoint-up (lg) {
        font-size: $font-size-h5;
    }
}

h6,
.h6 {
    font-size: $font-size-h6;
}


//== Paragraphs
//

p {
    font-size: $fs-base;
    font-weight: $fw-semi-bold;
    // margin-bottom: 0;
    margin-bottom: $fs-base * $line-height-base;
    // margin-bottom: 3rem;

    &+p {
        margin-top: $fs-base * $line-height-base;
    }
}


//== Links
//

a {
    font-size: $fs-base;
    color: $c-black;
    transition: $t-short $ease-default;

    &:hover {
        color: $c-copper;
    }
}

p {
    a {
        text-decoration: underline;
    }
}

.link {
    font-weight: $fw-black;
    display: inline-block;
    position: relative;
    line-height: 1.6;
    text-transform: uppercase;
    text-decoration: none !important;

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $c-black;
        will-change: transform, width;
        transition: $t-medium $ease-default;
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 3px;
        background-color: $c-copper;
        will-change: transform, width;
        transition: $t-medium $ease-default;
    }

    &:hover {
        &:after {
            width: 100%;
        }
    }

    &--lg {
        font-size: $fs-large;

        @include media-breakpoint-up (md) {
            font-size: $fs-x-large;
        }

        &:before,
        &:after {
            height: 5px;
        }
    }
}


//== Labels
//

.label {
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
}



//== Lists
//

ul,
ol {
    padding-left: 2rem;
    margin-bottom: 3rem;

    li {
        font-size: $fs-base;
        font-weight: $fw-semi-bold;

        p {
            margin-bottom: 0;
        }
    }
}



//== Columns
//

.columns {
    column-gap: 6rem;

    &--2 {
        column-count: 1;

        @include media-breakpoint-up (md) {
            column-count: 2;
        }
    }

    &--3 {
        column-count: 1;

        @include media-breakpoint-up (md) {
            column-count: 2;
        }

        @include media-breakpoint-up (xl) {
            column-count: 3;
        }
    }
}


.intro {

    * {
        font-size: $fs-large;
        line-height: 1.6;
        margin-bottom: 48px;
    }
}