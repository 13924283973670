@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/fonts/icomoon.eot?qk54h8');
  src: url('../fonts/icomoon/fonts/icomoon.eot?qk54h8#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/fonts/icomoon.ttf?qk54h8') format('truetype'),
    url('../fonts/icomoon/fonts/icomoon.woff?qk54h8') format('woff'),
    url('../fonts/icomoon/fonts/icomoon.svg?qk54h8#jsb-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
}
.icon-linkedin:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-play3:before {
  content: "\ea1c";
}

  // Mulish

  @font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-SemiBold.woff2') format('woff2'),
      url('../fonts/Mulish/Mulish-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-Black.woff2') format('woff2'),
      url('../fonts/Mulish/Mulish-Black.woff') format('woff');
    font-weight: 900;
    font-style: bold;
    font-display: swap;
  }

  @font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-SemiBold.woff2') format('woff2'),
      url('../fonts/Mulish/Mulish-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-Black.woff2') format('woff2'),
      url('../fonts/Mulish/Mulish-Black.woff') format('woff');
    font-weight: 900;
    font-style: bold;
    font-display: swap;
  }