.nav {
    display: none;
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    
    @include media-breakpoint-up (lg) {
        height: auto;
        position: relative;
        width: auto;
        display: block;
    }

    &__menu {
        display: inline-flex;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @include media-breakpoint-up (lg) {
            display: inline-flex;
            flex-direction: row;
            position: relative;
            transform: none;
        }
    }

    &__link {
        position: relative;
        z-index: 1;
        text-transform: uppercase;
        font-size: $fs-tiny;
        font-weight: $fw-black;
        padding: 0.6rem 1.8rem;
        letter-spacing: 0.1rem;
        color: $c-black;
        will-change: transform, color;
        transition: none;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }

        &.active {
            color: $c-white;

            .hover {
                transform: translateY(0) scaleY(1) !important;
                visibility: visible !important;
                opacity: 1 !important;
            }
        }

        .hover {
            position: absolute;
            z-index: -1;
            top: 0%;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $c-copper;
            will-change: transform, opacity;
            transform-origin: center center;
            visibility: hidden;
        }

        &--lang {
            font-weight: $fw-semi-bold;
            padding: 0.6rem 0.8rem;
            transition: $t-medium $ease-default;

            &:hover {
                color: $c-copper !important;
            }

            &.active {
                font-weight: $fw-black;
                color: $c-copper;
            }

            .hover {
                display: none;
            }
        }
    }


    // Burger icon

    &__burger {
        visibility: hidden;
        position: fixed;
        z-index: 999;
        top: 2rem;
        right: 2rem;
        width: 6rem;
        height: 6rem;
        overflow: hidden;
        transition: $t-medium $ease-default;

        @include media-breakpoint-up (lg) {
            display: none;
        }

        svg line {
            // transition: $t-long $ease-default;
        }

        &:hover {
            cursor: pointer;
        }

        &.white {
            svg line {
                stroke: $c-white;
            }
        }
    }

    // Nav background

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $c-light;

        @include media-breakpoint-up (lg) {
            display: none;
        }
    }
}


.lang__menu {
    margin-left: 3rem;
    display: inline-flex;
}