.video {
  position: relative;

  &:hover {
    cursor: pointer;

    .video__btn {
      &-inner {
        transform: scale(1.1);

        .video__btn-icon {
          opacity: 1;
        }
      }
    }
  }

  &__poster {
    width: 100%;
    position: relative;
    z-index: 3;
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 56.25%;

    iframe {
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__btn {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-inner {
      position: relative;
      display: block;
      margin: auto;
      background-color: $c-copper;
      border-radius: 100%;
      transition: $t-medium $ease-default;
      width: 8rem;
      height: 8rem;

      @include media-breakpoint-up (lg) {
        width: 10rem;
        height: 10rem;
      }

    }

    &-icon {
      color: $c-white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-40%, -50%);
      font-size: 3rem;
      opacity: 0.5;
      transition: $t-medium $ease-default;
    }
    
    &--sm {
      transform: translate(-50%, -50%) scale(0.5);
    }
  }

  &__fallback {
    display: none;
    width: 50%;
    position: absolute;
    margin: 3rem;
    
    * {
      color: $c-white;
    }
  }
}

.privacy--blocked {
  .video__fallback {
    display: block;
  }
}