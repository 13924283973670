.footer {
    background-color: $c-light;

    &__content {
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up (xl) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    p {
        font-size: $fs-small;
    }

    &__copyright {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include media-breakpoint-up (xl) {
            flex-direction: row;
        }

        >* {
            font-size: $fs-small;
            margin-bottom: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__logo {
        max-width: 4rem;
    }

    &__links {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        @include media-breakpoint-up (xl) {
            flex-direction: row;
        }
    }

    &__link {
        font-size: $fs-small;
        display: block;
        padding: 1rem;
    }

    &__social {
        display: flex;
        flex-direction: row;

        @include media-breakpoint-up (md) {
            position: fixed;
            z-index: 999;
            bottom: 3rem;
            right: 3rem;
            // transform: translate(0, -50%);
            flex-direction: column;
            justify-content: center;
        }

        @include media-breakpoint-up (xl) {
            margin-top: 0;
        }

        a {
            display: inline-block;
            font-size: 2rem;
            line-height: 1;
            margin: 0.75rem 0;
            padding: 1rem;
            background-color: $c-white;
            border-radius: 50%;
            will-change: transform;

            span:before {
                color: $c-black;
            }

            &:hover {
                text-decoration: none;
                transform: scale(1.1);
                background-color: $c-copper;
            }
        }

    }
}


//== Sticky Footer
//

// html {
//     position: relative;
//     min-height: 100%;
// }

// body {
//     margin-bottom: 12.4rem;
// }

// .footer {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
// }