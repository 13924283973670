.job-teaser {

    &__link {

        &:hover {
            color: inherit;
            text-decoration: none;

            .job-teaser__title {
                color: $c-copper;
            }
        }
    }

    &__title {
        position: relative;
        padding-right: 4rem;
        margin: 0;
        transition: $t-medium $ease-default;

        &:after {
            font-family: "icomoon";
            content: "\e900";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-size: 4rem;
        }
    }


}