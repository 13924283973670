.gallery {
    margin: 5vh 0;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        column-gap: 2px;
        column-count: 2;
        
        @include media-breakpoint-up (lg) {
            column-gap: 4px;
            column-count: 3;            
        }
    }

    li {
        position: relative;
        display: grid;
        grid-template-rows: 1fr auto;
        break-inside: avoid;
        overflow: hidden;
        margin-bottom: 2px;

        @include media-breakpoint-up (lg) {
            margin-bottom: 4px;            
        }
    }

    img {
        grid-row: 1 / -1;
        grid-column: 1;
        transition: $t-medium $ease-default;
        will-change: transform;
        
        &:hover {
            transform: scale(1.02);
        }
    }
}

.article {

    .gallery {
        width: calc(100% + 5.6rem);
        margin: 6vh 0 6vh -2.8rem;     

        @include media-breakpoint-up (lg) {
            width: 150%;
            margin-left: -25%;
        }
    }
}