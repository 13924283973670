.hero {
    position: relative;
    width: 100%;
    height: auto;
    background-color: $c-black;
    max-height: 100vh;
    overflow: hidden;

    &--half {
        height: 50vh;
    }

    &__title {
        display: block;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 90%;

        @include media-breakpoint-up (lg) {
            width: 75%;
        }

        @include media-breakpoint-up (xxl) {
            width: 66%;
        }

        >* {
            color: $c-white;
            font-size: $fs-xl-large;
            line-height: 1;

            @include media-breakpoint-up (lg) {
                font-size: $fs-xxl-large;
            }

            @include media-breakpoint-up (xxl) {
                font-size: $fs-jumbo;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__media {
        position: relative;
        z-index: 1;
        opacity: 0.6;
        min-height: 100vh;
        background-position: center center;
        background-size: cover;
    }

    &__video-fg,
    &__video-bg iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    @media (min-aspect-ratio: 16/9) {
        &__video-fg {
            height: 300%;
            top: -100%;
        }
    }

    @media (max-aspect-ratio: 16/9) {
        &__video-fg {
            width: 300%;
            left: -100%;
        }
    }

}
