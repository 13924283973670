
.form-header, .form h1, .form h2{
    word-wrap: break-word;
}

h1.form-header.no-space{
    margin-top: 50px;
    @include media-breakpoint-down (md) {
        margin-top: 15px;
    }
}
.special-logo{
    width: 300px;
    margin-top: 50px;
    @include media-breakpoint-down (md) {
        width: 50%;
        margin-top: 26px;
    }
}
.form{
    .form-control{
        height: inherit;
    }
    div{
        margin-bottom: 1.6rem;
        width: 44%;
        margin-right: 3%;
        display: inline-block;
        vertical-align: top;
        &.third{
            width: 31%;
            margin-right: 2%;
        }
        &.small{
            width: 200px;
            margin-right: 2%;
        }
        &.alone{
            display: block;
        }
        &.messagebox{
            background-color: rgb(98, 213, 98); 
            width: 100%;

            &.error{
                background-color:rgb(226, 62, 44);
            }

            p{
                margin: 0;
                color: #fff;
            }
        }
        @include media-breakpoint-down (md) {
            width: 100% !important;
            margin-right: 0;
            display: block;
        }
    }
    select.form-control[size], select.form-control[multiple]
    {
        height: 225px;
    }
    .required{
        position: relative;
    }
    .required::before
    {
        color: red;
        content: "*";
        width: 10px;
        height: 10px;
        position: absolute;
        right: -15px;
        display: inline-block;
    }
    .required.checkbox::before
    {
        left: -12px;
    }
    label, .radiogroup-label{
        font-size: $fs-tiny;
        margin-bottom: 0.5rem;
        display: inline-block;
    }
    .radiogroup-label ~ label{
        display: inline-block;
        margin-right: 10px;
    }
    input, select, textarea{
        width: 100%;
        font-size: $fs-tiny;
    }
    input[type=radio], input[type=checkbox]{
        width: auto;
    }
    input[type=checkbox]{
        margin-right: 15px;
    }
    input[type=checkbox]~label{
        display: initial;
    }
    .btn{
        font-size: $fs-base;
        background-color: $c-copper;
        color: #fff;
        border: 1px solid transparent;
        margin-right: 15px;
        cursor: pointer;
        &:hover{
            background-color: #fff;
            color: $c-copper;
            border: 1px solid $c-copper;
        }

        &.muted{
            background-color: #aaa;
            color: #fff; 
            &:hover{
                background-color: #fff;
                color: #aaa;
                border: 1px solid #aaa;
            }
        }
    }
}