body {

  &.privacy--allowed,
  &.privacy--blocked {
    #privacy {
      &:before {
        display: none;
      }
    }
  }

  &.privacy--allowed {
    //border-top: 3px solid copperyellow;
  }

  &.privacy--blocked {

    //border-top: 3px solid red;
    #privacy {
      background-color: transparent;
    }
  }

  #privacy {

    .cc-window {
      z-index: 9999999;
      max-width: 60rem;
      background-color: $c-black !important;
      padding: 2rem;
      border-radius: 2px;
      left: 50%;
      width: 90%;

      @include media-breakpoint-up (md) {
        padding: 4rem;
      }

      @include media-breakpoint-up (xl) {
        padding: 6rem;
      }
    }

    .cc-compliance {
      display: flex;
      flex-direction: row;
    }

    .cc-btn {
      font-size: $fs-base;
      padding: 1rem;
      border-radius: 2px;
      border-width: 2px;
      @include transition($t-short $ease-default);

      &:hover,
      &:focus {
        box-shadow: none;
        outline: none;
      }

      &:hover {
        text-decoration: none;
      }

      .cc-allow {
        background-color: $c-copper !important;
      }
    }

    .cc-allow {
      flex-basis: 65%;
      color: $c-white;

      a {
        background-color: $c-copper !important;
      }

      &:hover,
      &:focus {
        // transform: scale(1.03);
        text-decoration: none;
      }
    }

    .cc-deny {
      flex-basis: 35%;
      background-color: transparent;
      border: 2px solid rgba(255, 255, 255, 0.4);
      margin-left: 1rem;
      opacity: 0.8;

      &:hover {
        border-color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
      }
    }

    .cc-message {
      font-size: $fs-small;
      line-height: 1.5;
      color: $c-white;
      margin: 0 0 3rem 0;
      padding: 0;

      a {
        font-size: $fs-small;
      }
    }

    .cc-revoke {
      color: $c-white;
      background-color: $c-black;
      position: fixed;
      z-index: 999;
      right: 0;
      bottom: 0;
      padding: 0.5rem 1rem;
      font-size: $fs-tiny !important;
      font-weight: $fw-black;
      border-top-left-radius: 2px;
    }
  }
}


.cc-theme-edgeless.cc-window {
  background-color: $c-black !important;
  @include box-shadow(0 32px 48px -12px rgba(0, 0, 0, 0.5));
}

.cc-floating.cc-theme-edgeless .cc-message {
  color: $c-white;
  font-size: $fs-small;

  a {
    color: $c-white;
    font-size: $fs-small;
  }

  button {
    color: $c-white;
  }
}

.cc-compliance {

  .cc-highlight .cc-btn {
    color: $c-white;

  }
}

.cc-window {
  &.cc-center {
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    transform: translate(-50%, -50%);
    // display: none;
  }
}


// Always show revoke button
#privacy {
  .cc-revoke {
    display: block !important;
  }
}


.cc-highlight .cc-btn:first-child {
  background-color: $c-copper !important;
  color: $c-black !important;

  &:hover {
    text-decoration: none !important;
  }

}


.cc-revoke {
  font-size: $fs-tiny !important;
  background-color: $c-black !important;
  color: $c-white !important;
  padding: 0.25rem 0.5rem !important;
  right: 0;
  bottom: 0;
  user-select: none;
  display: none !important;
  
  &:hover {
    background-color: $c-copper !important;
    text-decoration: none !important;
    color: $c-black !important;
  }
}

body.datenschutz {
  .cc-revoke {
    display: block !important;
  }
}
body.data-privacy {
  .cc-revoke {
    display: block !important;
  }
}

