.slider {
    width: 100%;
    position: relative;

    &__main,
    &__thumbs {
        height: 100%;
        width: auto;
        // border: 1px solid $c-white;
    }

    &__img {
        opacity: 0.4;
        transition: $t-medium $ease-default;
    }

    &__slide {
        position: relative;
        background-color: $c-black;
    }

    &__inner {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-size: cover;
        background-position: center;
    }

    &__main {
        border-bottom: none;

        .swiper-slide-active {
            z-index: 2 !important;

            .slider__img {
                opacity: 1;
            }
        }
    }

    &__thumbs {
        border-top: 1px solid $c-white;

        .slider__slide {
            border-right: 1px solid $c-white;
            cursor: pointer;

            &:last-child {
                border-right: none;
            }
        }

        .swiper-slide-thumb-active {
            .slider__img {
                opacity: 1;
            }
        }
    }

    // Navigation
    // -----------------------

    .swiper-button-next,
    .swiper-button-prev {
        display: block;
        // padding: 2rem;
        width: 6rem;
        height: 6rem;
        background-image: none;
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: $t-medium $ease-default;

        span.icon-arrow {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
            color: $c-white;
            font-size: $fs-large;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            @include media-breakpoint-up (xl) {
                font-size: 48px;
            }
        }

        &:hover,
        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .swiper-button-disabled {
        pointer-events: auto;
    }

    .swiper-button-next {
        right: 3rem;
    }

    .swiper-button-prev {
        left: 3rem;
        transform: translateY(-50%);

        span.icon-arrow {
            &:before {
                transform: translate(-50%, -50%) scaleX(-1);
            }
        }
    }
}


.slider {
    &--blog {
        position: relative;
        height: 66vh;
        overflow: hidden;
        margin-bottom: 2px;

        .swiper {
            &-wrapper {
                height: 100%;
            }

            &-slide {
                height: 100%;
            }

            &-button-prev {
                left: auto;
                right: 11rem;
            }
        }

        .card {
            margin-bottom: 0;

            &__link {
                display: inline-block;
                width: 100%;
                height: 100%;
            }

            &__img {
                img {
                    transform: scale(1);
                    object-fit: cover;

                    &:hover {
                        transform: scale(1);
                    }
                }
            }
        }

        @include media-breakpoint-down (md) {

            .swiper-button-next,
            .swiper-button-prev {
                top: auto;
                bottom: 2rem;
                left: 50%;
                right: auto;
            }

            .swiper-button-next {
                transform: translate(0);
            }

            .swiper-button-prev {
                transform: translate(-100%);

                @include media-breakpoint-up (lg) {
                    right: 11rem;
                    left: auto;
                }
            }
        }


    }
}