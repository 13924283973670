.cover {
    position: relative;
    width: 100%;
    background-color: $c-black;
    overflow: hidden;
    height: 50vh;

    @include media-breakpoint-up (md) {
        height: 66vh;
    }

    &__share {
        position: absolute;
        z-index: 3;
        top: 50%;
        right: 3rem;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;

        a {
            opacity: 1;
            margin: 1rem 0;
            display: block;
            line-height: 1;
            font-size: 3rem;
            will-change: transform;
            transition: $t-short $ease-default;
            color: $c-white;

            &:hover {
                text-decoration: none;
                transform: scale(1.1);
            }
        }
    }

    &__lbl {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        @include media-breakpoint-up (lg) {
            max-width: 75%;
        }
    }

    &__title {
        margin: 0;
        color: $c-white;
        line-height: $line-height-headlines;
        font-size: $fs-x-large;

        @include media-breakpoint-up (md) {
            font-size: $fs-xxl-large;
        }
    }

    &__tags {
        margin-top: 1rem;
        white-space: nowrap;
    }

    &__date {
        margin-top: 0.5rem;
        letter-spacing: $ls-large;
        text-transform: uppercase;
        color: $c-white;
        display: block;
        font-size: $fs-tiny;

        @include media-breakpoint-up (md) {
            font-size: $fs-small;

        }
    }

    &__img {
        opacity: 0.6;
        object-fit: cover;
        height: 100%;
    }
}