.infos {
    display: flex;
    flex-direction: row;

    &__info {
        font-size: $fs-base;

        &:after {
            position: relative;
            content: "∙";
            padding: 0 0.5rem;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }
}
