blockquote {
    border-left: 2px solid $c-black;
    font-size: $fs-base;
    font-weight: $fw-black;
    padding-left: 3rem;
    
    @include media-breakpoint-up (lg) {
        padding-left: 4rem;        
    }

    footer {
        margin-top: 1rem;
        font-size: $fs-tiny;
        font-weight: $fw-semi-bold;
        text-transform: uppercase;
        letter-spacing: $ls-large;

        &:before {
            content: "— ";
        }
    }
}

.article {
    blockquote {
        margin: 3vh 0;
    }
}