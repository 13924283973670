img {
    width: 100%;
    height: auto;
}

figcaption {
    font-size: $fs-tiny;
    font-weight: $fw-semi-bold;
    margin-top: 8px;
    opacity: 0.6;
    line-height: 1.4;
    margin-left: 3rem;
    padding-right: 3rem;
    
    @include media-breakpoint-up (lg) {
        margin-left: 0;
        padding-right: 0;
    }
}

.article {
    .image {
        width: calc(100% + 5.6rem);
        margin: 6vh 0 6vh -2.8rem;            
        
        @include media-breakpoint-up (lg) {
            width: 150%;
            margin: 6vh 0 6vh -25%;            
        }
    }
}