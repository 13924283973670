.grid {
    margin: 0 2px;
    padding: 0;
    list-style: none;
    column-gap: 2px;
    column-count: 1;

    @include media-breakpoint-up (md) {
        column-count: 2;
    }

    @include media-breakpoint-up (lg) {
        column-gap: 4px;
        column-count: 3;
    }

    &__item {
        visibility: hidden;
        position: relative;
        display: grid;
        grid-template-rows: 1fr auto;
        break-inside: avoid;
        overflow: hidden;
        margin-bottom: 2px;

        @include media-breakpoint-up (lg) {
            margin-bottom: 4px;
        }
    }


    // BLOG Grid
    &--blog {
        display: grid;
        gap: 2px;
        grid-template-columns: 1fr;


        @include media-breakpoint-up (lg) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}


.blog--start {
    .grid__item {
        &:first-child {
            grid-column: 1 / -1;
            max-height: 50vh;
            margin-bottom: 0;

            >* {
                max-height: 50vh;
            }
        }
    }
}