iframe {
    border: 2px solid $c-black;
}

.article {
    iframe {
        min-height: 50vh;
        width: calc(100% + 5.6rem);
        margin: 6vh 0 6vh -2.8rem;            
        
        @include media-breakpoint-up (lg) {
            width: 150%;
            margin: 6vh 0 6vh -25%;            
        }
    }
}