body.video {

    header {
        display: none;
    }

    #privacy {
        display: none;
    }

    footer {
        display: none;
    }

    #main {
        width: 100vw;
        position: relative;
        height: 100vh;
        background: $c-black;
    }

    figure.video {
        width: 100%;
        height: 100%;
        
        video {
            will-change: opacity;
            transition: 0.4s ease-out;
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .playBtn {
        will-change: transform;
        display: none;
        position: absolute;
        z-index: 99;
        background-color: $c-white;
        color: $c-black;
        font-size: $fs-x-large;
        font-weight: $fw-black;
        text-transform: uppercase;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 4rem 8rem;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

            >span:first-child {
                margin-right: 1rem;
            }
    }
}

