.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: $c-black;
    margin: 2px;

    > * {
        display: inline-block;
        text-align: center;
        background-color: $c-black;
        color: $c-white;
        border-right: 1px solid $c-white;
        border-left: 1px solid $c-white;
        font-size: $fs-tiny;
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        
        @include media-breakpoint-up (lg) {
            width: 6rem;
            height: 6rem;            
            line-height: 6rem;
            font-size: $fs-base;
        }

        &:hover {
            text-decoration: none;
        }

        &:first-child {
            border-left-width: 2px;
        }
        
        &:last-child {
            border-right-width: 2px;
        }
    }

    span {
        &.pagination__prev,
        &.pagination__next {
            // opacity: 0.3;
            color: lighten($c-black, 10%);
        }
    }

    &__prev,
    &__next {
        position: relative;

        > span {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    &__prev {
        > span {
            transform: translate(-50%, -50%) scaleX(-1);
        }
    }

    &__indicator {
        font-size: $fs-small;
        font-weight: $fw-black;

        &.active {
            background-color: $c-copper;
        }
    }
}