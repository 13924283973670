.header {
    // mix-blend-mode: difference;
    // position: fixed;
    // z-index: 999;
    // top: 0;
    // left: 0;
    position: relative;
    z-index: 999;
    width: 100%;
    padding: 2rem 0;

    &__inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

