//== Colors
//

$c-white: #ffffff;
// $c-black: #000000;
$c-black: #414042;
$c-light: #FAFAFA;
// $c-copper: #907B4E;
$c-copper: #F5A800;

//== Font Family
//

$ff: Muli, sans-serif;
$font-family-sans-serif: $ff !default;
$font-family-base: $ff !default;

// Font Weight
$fw-semi-bold: 600;
$fw-black: 900;

// Letter Spacing
$ls-micro: 0.2rem;
$ls-button: 0.1rem;
$ls-large: 0.1rem;
$ls-base: 0.05rem;
$ls-copy: 0.01rem;
$ls-data: 0;
$ls-h1: 0;
$ls-h2: 0;
$ls-h3: 0;

// Font Size
$fs-tiny: 14.4px !default;
$fs-small: 16.6px !default;
$fs-base: 18px !default;
$fs-large: 24px !default;
$fs-x-large: 36px !default;
$fs-xl-large: 48px !default;
$fs-xxl-large: 64px !default;
$fs-jumbo: 120px !default;
$font-size-h1: $fs-xxl-large !default;
$font-size-h2: $fs-xl-large !default;
$font-size-h3: $fs-x-large !default;
$font-size-h4: $fs-large !default;
$font-size-h5: $fs-base !default;
$font-size-h6: $fs-small !default;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.8 !default; // 20/14
$line-height-headlines: 1.2;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($fs-base * $line-height-base)) !default; // ~20px


//== Transitions
//

// Timing
$t-x-short : 0.1s;
$t-short : 0.2s;
$t-medium : 0.3s;
$t-long : 0.6s;
$t-x-long : 0.9s;
$t-xl-long : 1.2s;


// EASING

// Cubic
$ease-in-cubic : cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-out-cubic : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-in-out-cubic : cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Circ
$ease-in-circ : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ease-out-circ : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ease-in-out-circ : cubic-bezier(0.785, 0.135, 0.150, 0.860);

// Expo
$ease-in-expo : cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-out-expo : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-in-out-expo : cubic-bezier(1.000, 0.000, 0.000, 1.000);

// Quad
$ease-in-quad : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-out-quad : cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-in-out-quad : cubic-bezier(0.455, 0.030, 0.515, 0.955);

// Quart
$ease-in-quart : cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-out-quart : cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-in-out-quart : cubic-bezier(0.770, 0.000, 0.175, 1.000);

// Quint
$ease-in-quint : cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-out-quint : cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-in-out-quint : cubic-bezier(0.860, 0.000, 0.070, 1.000);

// Sine
$ease-in-sine : cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ease-out-sine : cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ease-in-out-sine : cubic-bezier(0.445, 0.050, 0.550, 0.950);

// Back
$ease-in-back : cubic-bezier(0.600, -0.280, 0.735, 0.045);
$ease-out-back : cubic-bezier(0.175, 0.885, 0.320, 1.275);
$ease-in-out-back : cubic-bezier(0.680, -0.550, 0.265, 1.550);

$ease-default : $ease-out-quad;



// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((
0: 0,
1: ($spacer * 1),
2: ($spacer * 2),
3: ($spacer * 3),
4: ($spacer * 4),
5: ($spacer * 5),
6: ($spacer * 6),
7: ($spacer * 7),
8: ($spacer * 8),
9: ($spacer * 9),
10: ($spacer * 10),
11: ($spacer * 11),
12: ($spacer * 12),
13: ($spacer * 13),
14: ($spacer * 14),
15: ($spacer * 15),
16: ($spacer * 16),
17: ($spacer * 17),
18: ($spacer * 18),
19: ($spacer * 19),
20: ($spacer * 20),
21: ($spacer * 21),
22: ($spacer * 22),
23: ($spacer * 23),
24: ($spacer * 24),
30: ($spacer * 30),
31: ($spacer * 31),
32: ($spacer * 32),
33: ($spacer * 33),
34: ($spacer * 34),
35: ($spacer * 35),
36: ($spacer * 36)
), $spacers);



//== Add custom media queries breakpoints
//

$grid-breakpoints: (
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1680px,
  xxxxl: 1920px,
  xxxxxl: 2560px
);

$container-max-widths: (
  sm: 568px,
  md: 667px,
  lg: 768px,
  xl: 992px,
  xxl: 1200px,
  xxxl: 1440px,
  xxxxl: 1680px,
  xxxxxl: 1920px
);


//== Change container padding
//

$grid-gutter-width: 60px !default;