.card {
    position: relative;
    // margin-bottom: 2px;
    overflow: hidden;
    box-sizing: border-box;
    height: 100%;

    // @include media-breakpoint-up (lg) {
    //     margin-bottom: 4px;
    // }

    &.ready {

        .card__img {
            transition: $t-long $ease-default;

            img {
                transition: 4s $ease-default;
            }
        }

        &:hover {
            .card__img {

                img {
                    filter: grayscale(0) blur(0);
                    opacity: 1;
                }
            }
        }
    }

    &__img {
        background-color: black;
        height: 100%;

        img {
            backface-visibility: hidden;
            will-change: filter, opactiy;
            filter: grayscale(50%) blur(2px);
            opacity: 0.75;
            transform: scale(1.05);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__link {
        display: block;
    }

    &__lbl {
        position: absolute;
        z-index: 3;
        opacity: 1;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        padding-left: 6rem;

        @include media-breakpoint-up (lg) {
            padding-right: 2rem;
        }
    }

    &__tag {
        margin-bottom: 0.7rem;
    }

    &__title {
        color: $c-white;
        transition: $t-medium $ease-default;
        text-shadow: 0px 5px 12px rgba(0, 0, 0, 0);
        font-size: $fs-large;

        @include media-breakpoint-up (xl) {
            font-size: $fs-x-large;
        }

        @include media-breakpoint-up (xxxxl) {
            font-size: $fs-xl-large;
        }

        @include media-breakpoint-up (xxxxxl) {
            font-size: $fs-xxl-large;
        }
    }

    &__client {
        display: inline-block;
        position: relative;
        color: $c-white;
        transition: $t-medium $ease-default;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: -6rem;
            width: 3rem;
            height: 100%;
            background-color: $c-copper;
            transition: $t-medium $ease-default;
            will-change: width, left;
        }
    }



    &__curtain {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform-origin: center top;
        opacity: 0.8;
    }

    &:hover {

        .card__title {
            text-shadow: 0px 10px 12px rgba(0, 0, 0, 0.7);
        }

        .card__client {
            transform: translateX(1.5rem);

            &:before {
                width: calc(100% + 3rem);
                left: -1.5rem;
            }
        }

        .card__img {
            img {
                transform: scale(1);
            }
        }

    }
}