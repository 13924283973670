.tag {
    background: $c-white;
    color: $c-black;
    padding: 0.5rem 1rem;
    font-size: $fs-small;
    display: inline-block;
    line-height: 1;
    text-transform: capitalize;

    &+.tag {
        margin-left: 0.25rem;
    }
}